var level_3_0_1 = {
    "0" : {
      "name": "Review of HR frameworks",
      "hash": "HRFramework"
    },
    "1" : {
      "name": "Learning & Development",
      "hash": "LearningDevelopment"
    }
};

var level_3_0_2 = {
    "0" : {
      "name": "Community Care Day",
      "hash": "CommunityCareDay"
    },
    "1" : {
      "name": "Home Refresh!",
      "hash": "HomeRefresh"
    },
    "2" : {
      "name": "Supporting National Initiatives",
      "hash": "SupportingNationalInitiatives"
    }
};

var level_3_0_3 = {
    "0" : {
      "name": "Celebrations & Team Bonding",
      "hash": "CelebrationsTeamBonding"
    },
    "1" : {
      "name": "AIC in Conversation",
      "hash": "AICInConversation"
    },
    "2" : {
      "name": "Kopi Chats",
      "hash": "KopiChats"
    }
};

var level_3_0_4 = {
    "0" : {
      "name": "Fitness Resources",
      "hash": "FitnessResources"
    },
    "1" : {
      "name": "Wellness Wednesday",
      "hash": "WellnessWednesday"
    },
    "2" : {
      "name": "Workplace Emotional Health",
      "hash": "WorkplaceEmotionalHealth"
    }
};


var level_2_0 = {
  '0' : {
    name : 'Human Resources (HR)',
    hash : 'HumanResources',
    links : level_3_0_1 
  },
  '1' : {
    name : 'AIC Cares',
    hash : 'AICCares',
    links : level_3_0_2 
  },
  '2' : {
    name : 'Staying Connected',
    hash : 'StayingConnected',
    links : level_3_0_3 
  },
  '3' : {
    name : 'Caring for All of Us',
    hash : 'CaringforAll',
    links : level_3_0_4 
  },
}

var level_3_1_0 = {
  '0' : {
    name : 'Innovation & Learning Fiesta',
    hash : 'InnovationLearningFiesta',
  },
  '1' : {
    name : 'Think Thursday',
    hash : 'ThinkThursday',
  },
  '2' : {
    name : 'InnovAIC Lab',
    hash : 'InnovAICLab',
  },
}

var level_2_1 = {
  '0' : {
    name: 'Fostering a Culture of Innovation',
    hash: 'FosteringCultureInnovation',
    links: level_3_1_0,
  }
}


var level_3_2_0 = {
  '0' : {
    name : 'Financial Management',
    hash : 'FinancialManagement',
  },
  '1' : {
    name : 'IT Systems',
    hash : 'ITSystems',
  },
  '2' : {
    name : 'Data Protection',
    hash : 'DataProtection',
  },
  '3' : {
    name : 'Risk Management',
    hash : 'RiskManagement',
  },
}

var level_2_2 = {
  '0' : {
    name: 'Reinforcing Systems & Governance',
    hash: 'ReinforcingSystemsGovernance',
    links: level_3_2_0,
  }
}

var level_2_3 = {
  '0' : {
    name: 'Looking Ahead',
    hash: 'LookingAhead',
  }
}



var level_1 = {
  '0' : {
    name: 'Nurturing Our People',
    sections: level_2_0,
    active: false
  },

  '1' : {
    name: 'Fostering a Culture of Innovation',
    sections: level_2_1,
    active:false
  },

  '2' : {
    name: 'Reinforcing Systems & Governance',
    sections: level_2_2,
    active:false
  },
  '3' : {
    name: 'Looking Ahead',
    sections: level_2_3,
    active:false
  }
}

export default level_1; 