<template>
    <section class="content-container row justify-content-center" id='description' name='Together as #OneAICFamily'>
        <div class="col-12 px-3">
            <p>As our colleagues work tirelessly to support seniors, clients and partners, we are redoubling efforts to ensure that they stay engaged, supported and healthy. We also believe in fostering a conducive work environment through the continual pursuit of innovation while seeking to be an efficient, integrated and well-governed organisation. Towards this, we are working on building up our forward-planning capability, to anticipate emerging issues impacting Community Care and translating insights and national policies into strategies. We will also develop our research and data capabilities to support our planning, and better measure the impact of AIC's initiatives.</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Description',
}
</script>
