<template>
    <div>
        <section id='FosteringCultureInnovation' class="content-container" name='Fostering a Culture of Innovation'>
            <div class="titles">                
                <h2>Fostering a Culture of Innovation</h2>
            </div>
            <div class="col-12 px-3">
                <p>At AIC, we encourage a staff-driven innovation approach based on the IDEAte framework (Imagine, Develop, Evaluate, Accelerate) and pursue fresh ideas and methods through co-learning.</p>
            </div>
        </section>

        <section id='InnovationLearningFiesta' class="content-container" name='Innovation & Learning Fiesta'>
            <div class="titles">
                <h4>Innovation &amp; Learning Fiesta</h4>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/one-aic-family/image_10.jpg" alt="">
                </div>
                <p class="caption">Embracing change, innovating for the future</p>
            </div>
            <div class="col-12 px-3">
                <p>The inaugural Innovation & Learning Fiesta, held in November 2020, was a week-long virtual event designed to nurture AIC’s spirit of innovation. Various talks and workshops explored the themes of ‘Embracing Change’ and ‘Service, Process and People Innovation’.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/one-aic-family/infograph_10.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p class="line">The event attracted nearly <span class="stats">500</span>  participants.</p>
                             <p><span class="stats">10</span> AIC Innovation projects were showcased and <span class="stats">19</span> learning activities conducted.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='ThinkThursday' class="content-container" name='Think Thursday'>
            <div class="titles">
                <h4>Think Thursday</h4>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/one-aic-family/image_11.jpg" alt="">
                </div>
                <p class="caption">A speaker from Google spoke on their organisation’s innovation culture</p>
            </div>
            <div class="col-12 px-3">
                <p>These bimonthly mailers feature curated resources such as articles, videos, tips, case studies and brainstorming tools. We have also organised four webinars featuring external speakers such as Google and Government Technology Agency of Singapore to share on innovation culture and tips to drive personal innovation.</p>
            </div>
        </section>

        <section id='InnovAICLab' class="content-container" name='InnovAIC Lab'>
            <div class="titles">
                <h4>InnovAIC Lab</h4>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/one-aic-family/image_12.jpg" alt="">
                </div>
                <p class="caption">A view of the new collaboration space</p>
            </div>
            <div class="col-12 px-3">
                <p>Launched in 2020, the InnovAIC Lab is a flexible open space designed to encourage collaboration and brainstorming located on Level 4 of MND Building Annex B. It offers facilities such as an interactive projector and breakout booths.</p>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'FosteringCultureInnovation',
}
</script>
<style scoped>

</style>
