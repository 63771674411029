<template>
    <div>
        <section id='CaringforAll' class="content-container" name='Caring for All of Us'>
            <div class="titles">
                <h4>Caring for All of Us</h4>
            </div>
            <div class="col-12 px-3">
                <p>Our people are at the heart of what we do, so taking care of their physical, emotional and mental wellbeing – whether through self-care or mutual support – will always be a priority.</p>
            </div>
        </section>

        <section id='FitnessResources' class="content-container" name='Fitness Resources'>
            <div class="titles">
                <h5>Fitness Resources</h5>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/one-aic-family/image_7.jpg" alt="">
                </div>
                <p class="caption">One of the fitness sessions that took place online</p>
            </div>
            <div class="col-12 px-3">
                <p>In 2020, we provided staff with Ministry of Fitness gym passes. In addition, we kept employees active during the work-from-home period with resources on mental and physical wellbeing and activities that they could do at home.</p>
            </div>
        </section>

        <section id='WellnessWednesday' class="content-container" name='Wellness Wednesday'>
            <div class="titles">
                <h5>Wellness Wednesday</h5>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/one-aic-family/image_8.jpg" alt="">
                </div>
                <p class="caption">One of the many activities organised as part of Wellness Wednesday</p>
            </div>
            <div class="col-12 px-3">
                <p>We dedicate this day of the week to promoting physical and mental wellness and reminding our staff to practise self-care. Activities include virtual group workouts, mental health workshops and weekly communications with wellness tips. We have also designated 5pm to 6pm on Wednesday as Wellness Hour for staff to exercise and participate in self-care workshops.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/one-aic-family/infograph_8.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">40</span> Wellness Wednesday activities have been conducted since 2020.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='WorkplaceEmotionalHealth' class="content-container" name='Workplace Emotional Health'>
            <div class="titles">
                <h5>Workplace Emotional Health</h5>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/one-aic-family/image_9.jpg" alt="">
                </div>
                <p class="caption">Our staff’s mental and emotional wellbeing are a priority</p>
            </div>
            <div class="col-12 px-3">
                <p>To provide a listening ear to colleagues in distress, the AIC Peer Support System was set up in 2019. This network is made up of staff trained to provide basic emotional support. For those who need professional support, we have provided access to external counselling partners.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/one-aic-family/infograph_9.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>We currently have over <span class="stats">50</span> peer supporters and more than <span class="stats">10</span> peer support leaders.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
export default {
    name: 'KeepingFitWell',
}
</script>
<style scoped>

</style>
