<template>
    <div>
        <section id='AICCares' class="content-container" name='AIC Cares'>
            <div class="titles">
                <h4>AIC Cares</h4>
            </div>
            <div class="col-12 px-3">
                <p>We recognise that, beyond our daily work, we all have the power to give back to society. We offer all staff hands-on opportunities to contribute back to our community.</p>
            </div>
        </section>

        <section id='CommunityCareDay' class="content-container" name='Community Care Day'>
            <div class="titles">
                <h5>Community Care Day</h5>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/one-aic-family/image_3.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
                <p class="caption">AIC volunteers bringing residents from Sree Narayana Mission Nursing Home to the nearby Chong Pang Food Centre for lunch</p>
            </div>
            <div class="col-12 px-3">
                <p>In conjunction with the annual <a href='/support-partners#CommunityCareDay2020'>Community Care Day</a> celebration on 1 November, AIC employees showed their appreciation to our Community Care partners through a fruitful day of volunteering alongside our sector colleagues, leading seniors in activities such as bingo, modified sports and chair zumba.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/one-aic-family/infograph_2.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Since 2019, we have worked with <span class="stats">5</span> Community Care partners.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='HomeRefresh' class="content-container" name='Home Refresh!'>
            <div class="titles">
                <h5>Home Refresh!</h5>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/one-aic-family/image_4.jpg" alt="">
                    <p class="credits">(The activity took place prior to COVID-19)</p>
                </div>
                <p class="caption">Post-event flyer to thank volunteers</p>
            </div>
            <div class="col-12 px-3">
                <p>Through this outreach initiative, we visit the flats of seniors and helped them to declutter and refresh their living conditions.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/one-aic-family/infograph_3.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>In 2019, we completed <span class="stats">3</span> runs of Home Refresh!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='SupportingNationalInitiatives' class="content-container" name='Supporting National Initiatives'>
            <div class="titles">
                <h5>Supporting National Initiatives</h5>
            </div>
            <div class="col-12 px-3">
                <p>As members of the Singapore public service, we also answered the call for support at the national level and volunteered in various aspects of COVID-19 operations. These included packing and delivering swab test kits, distributing meals and participating in vaccination drives.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/one-aic-family/infograph_4.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">150</span> AIC colleagues volunteered in COVID-19 operations.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'AICCares',
}
</script>
<style scoped>
</style>
