<template>
	<div id="one-aic-family" :class='fontsize'>
    
		<Intro :intro="intro"/>

		<div class="container-fluid secondary-section fontsize" id="secondary-content">
			<div class="row">
  			 
  			  <SubNavOne :navLinks='navLinks'/>

			    <section id='Navigation' class="content ml-auto">

					<Description/>					
					
					<HumanResource/>

					<AICCares/>

					<StayingConnected/>

					<KeepingFitWell/>

					<FosteringCultureInnovation/>

					<ReinforcingSystemsGovernance/>

				</section>

				<PageNavigation :prev="'/looking-ahead'" :next="'/our-organisation'" />

			</div>
		</div>
	</div>	
</template>

<script>
// @ is an alias to /src
import SubNavOne from '@/components/SubNavOne.vue'
import NavLinks from '@/components/one-aic-family/navLinks.js'

import Intro from '@/components/Intro.vue'
import Description from '@/components/one-aic-family/Description.vue'
import HumanResource from '@/components/one-aic-family/HumanResource.vue'
import AICCares from '@/components/one-aic-family/AICCares.vue'
import StayingConnected from '@/components/one-aic-family/StayingConnected.vue'
import KeepingFitWell from '@/components/one-aic-family/KeepingFitWell.vue'
import FosteringCultureInnovation from '@/components/one-aic-family/FosteringCultureInnovation.vue'
import ReinforcingSystemsGovernance from '@/components/one-aic-family/ReinforcingSystemsGovernance.vue'
import PageNavigation from '@/components/PageNavigation.vue'

import dynamicFontSizeMixin from '@/mixin/dynamicFontSizeMixin'

export default {
		name: 'OneAicFamily',

    data() {
		return {
			scroll: 0,
			sections: '',
			currentSec: '',
			navLinks : NavLinks,
			hashes: [
			],
			intro: {
                title: 'Together as #OneAICFamily',
                secondTitle: '',
                img: this.mobileAndTabletCheck() ? require("@/assets/one-aic-family/banner-mobile.jpg") : require("@/assets/one-aic-family/banner.jpg")
            }
    	}
    },

		mixins: [dynamicFontSizeMixin],

		components: {
			SubNavOne,
			Intro,
			Description,
			HumanResource,
			AICCares,
			StayingConnected,
			KeepingFitWell,
			FosteringCultureInnovation,
			ReinforcingSystemsGovernance,
			PageNavigation
		},
}
</script>

<style>
 /* media */
@media (min-width: 768px) {
	.content{
	 	width: 70vw;
	}
}
</style>
