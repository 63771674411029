<template>
    <div>
        <section id='HumanResource' class="content-container" name='Human Resources (HR)'>
            <div class="titles">
                <h2>Nurturing Our People</h2>
                <h4>Human Resources (HR)</h4>
            </div>
            <div class="col-12 px-3">
                <p>At AIC, we are committed to developing our staff and providing them with opportunities to learn, grow and have a meaningful career at AIC.</p>
            </div>
        </section>

        <section id='HRFramework' class="content-container" name='Review of HR frameworks'>
            <div class="titles">
                <h5>Review of HR frameworks</h5>
            </div>
            <div class="col-12 secondary-image-container">
                    <img src="@/assets/one-aic-family/image_1.jpg" alt="">
                    <p class="caption">AIC 2.0 Core and Leadership Competencies</p>
            </div>
            <div class="col-12 px-3">
                <p>We comprehensively reviewed our HR frameworks in 2020 in line with our refreshed operating model and corporate strategic outcomes. We reformulated our set of AIC 2.0 Core and Leadership Competencies to deliver outcomes and lead change across the organisation. We refined our performance management framework to enhance our performance-driven culture.</p>
            </div>
        </section>
        <section id='LearningDevelopment' class="content-container" name='Learning & Development'>
            <div class="titles">
                <h5>Learning & Development</h5>
            </div>
            <div class="col-12 secondary-image-container">
                    <img src="@/assets/one-aic-family/image_2.jpg" alt="">
                    <p class="caption">Staff at an AIC milestone leadership programme</p>
            </div>
            <div class="col-12 px-3">
                <p>As part of this review, we also refreshed and relaunched our learning and development framework to support employee development across different levels. In 2020, we launched a series of three in-house milestone leadership development programmes for staff at various stages of their leadership journey. </p>

                <p>AIC CORNERSTONE, a leadership skilling programme for our new managers, equips them with basic leadership principles and values, and practical people management skills. Through AIC COMPASS, our more experienced leaders explore leadership topics that were closely aligned with the AIC 2.0 competencies. AIC CATALYST focuses on leaders who facilitate change and transformation. Topics include performance and team diversity, design thinking, service innovation, influence and strategy transformation.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/one-aic-family/infograph_1.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Close to <span class="stats">70</span> employees have attended the leadership programmes.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'HumanResource',
}
</script>
<style scoped>
</style>
