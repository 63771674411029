<template>
    <div>
        <section id='FinancialManagement' class="content-container" name='Financial Management'>
            <div class="titles">
                <h2>Reinforcing Systems &amp; Governance</h2>
                <h4>Financial Management</h4>
            </div>
            <div class="col-12 px-3">
                <p>AIC manages a significant amount of public funds on behalf of the Ministry of Health (MOH). As such, it is critical that our systems and processes remain robust and efficient. We have leveraged technology to implement robotics process automation and put new finance systems in place, improving processes without compromising on governance.</p>
            </div>
        </section>

        <section id='ITSystems' class="content-container" name='IT Systems'>
            <div class="titles">
                <h4>IT Systems</h4>
            </div>
            <div class="col-12 px-3">
                <p>To ensure AIC and the sector can better support partners, we established the AIC Digital Strategies to guide the digital development for the sector. We enhanced existing systems such as the <a href='/looking-ahead#Idp'> Health Marketplace</a> and the ILTC Referral Management System, and reviewed the Nursing Home IT Enablement Programme. We also supported the launch of online applications for <a href='/support-seniors#CareShieldLifeMediSaveCare'>CareShield Life & MediSave Care</a> via <a href='/support-seniors#eServicesFinancingSchemes'>eServices for Financing Schemes</a>. Moving forward, we will be working with the sector to map out an Industry Digital Plan. </p>

                <p>To support remote work in 2020 and beyond, we implemented video conferencing services and strengthened our Virtual Private Network for secure access from home. We also launched an AIC Staff Cyber Security Training Roadmap to improve IT security awareness and keep AIC safe from cyber threats.</p>
            </div>
        </section>

        <section id='DataProtection' class="content-container" name='Data Protection'>
            <div class="titles">
                <h4>Data Protection</h4>
            </div>
            <div class="col-12 px-3">
                <p>AIC continuously enhances its data protection measures in order to safeguard client and partner information. We raise corporate awareness of data protection practices through Communities of Practice and regular communication with staff via briefings and e-newsletters. We also conduct annual reviews of our processes and policies in line with the Personal Data Protection Act and MOH’s prevailing requirements.</p>
            </div>
        </section>

        <section id='RiskManagement' class="content-container" name='Risk Management'>
            <div class="titles">
                <h4>Risk Management</h4>
            </div>
            <div class="col-12 px-3">
                <p>We enhanced our Enterprise Risk Management Framework in 2020. This framework guides us in identifying and managing key operational and reputational risks, and we have worked to close the gaps in the area of strategic corporate risks. </p>
                <p>In 2020, we also developed a pandemic-specific corporate Business Continuity Plan to ensure that AIC is able to quickly resume operations that are critical to our role of supporting clients and partners.</p>
            </div>
        </section>

        <section id='LookingAhead' class="content-container" name='Looking Ahead'>
           <div class="titles">
                <h2>Looking Ahead</h2>
            </div>

            <div class="col-12 px-3">
                <p>Since the pandemic, we have rolled out many initiatives to keep our staff engaged and strong, and to maintain morale. They are targeted at organisational, divisional and individual levels, such as leadership engagement, team cohesion activities and physical and mental wellness support. These initiatives also prepare us as an organisation as we adapt to the new normal and move towards a hybrid mode of working in future. </p>
            </div>
        </section>

    </div>
</template>

<script>
export default {
    name: 'FosteringCultureInnovation',
}
</script>
<style type="text/css" scope>
    .titles {
        margin-top: 40px;
    }
</style>