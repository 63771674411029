<template>
    <div>
        <section id='StayingConnected' class="content-container" name='Staying Connected'>
            <div class="titles">
                <h4>Staying Connected</h4>
            </div>
            <div class="col-12 px-3">
                <p>Even as we work hard as an organisation, we make sure to stay connected as #OneAICFamily, coming together to celebrate our milestones and listen to each other’s views and concerns.</p>
            </div>
        </section>

        <section id='CelebrationsTeamBonding' class="content-container" name='Celebrations & Team Bonding'>
            <div class="titles">
                <h5>Celebrations & Team Bonding</h5>
            </div>
            <div class="col-12 px-3">
                <p>Get-togethers to celebrate successes, connect on work plans for the year or just to bond are a norm at AIC. Events include the annual work plan seminar, AIC’s birthday, and other festive and commemorative occasions. Even though COVID-19 required us to work from home for most of 2020, we held fast to this tradition and shifted our activities online instead.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/one-aic-family/infograph_5.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p class="line pt-2 pb-md-3">Between 2019 and 2020, we organised over <span class="stats">10</span> events.</p>
                             <p class='mt-3'>We conducted over <span class="stats">120</span> virtual team-bonding activities since COVID-19.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='AICInConversation' class="content-container" name='AIC in Conversation'>
            <div class="titles">
                <h5>AIC in Conversation</h5>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/one-aic-family/image_5.jpg" alt="">
                </div>
                <p class="caption">Staff catching up with our Chairman, Mr Gerard Ee</p>
            </div>
            <div class="col-12 px-3">
                <p>This is a series of cosy and informal sessions hosted by the senior leadership team to catch up with staff and to hear their concerns. Conversations revolve around topics such as work-life balance, performance management and leveraging technology.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/one-aic-family/infograph_6.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>In 2020, we organised <span class="stats">11</span> conversations involving over <span class="stats">110</span> participants.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='KopiChats' class="content-container" name='Kopi Chats'>
            <div class="titles">
                <h5>Kopi Chats</h5>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/one-aic-family/image_6.jpg" alt="">
                </div>
                <p class="caption">A monthly EDM inviting staff to a chat with CEO Mr Tan Kwang Cheak</p>
            </div>
            <div class="col-12 px-3">
                <p>We conduct these monthly Monday morning chats with our CEO and other leaders via Workplace, where they share regular updates with the entire AIC team. During each hour-long session, staff can also freely ask their leaders questions.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/one-aic-family/infograph_7.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Since 2020, we have hosted <span class="stats">13</span> Kopi Chat sessions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
export default {
    name: 'StayingConnected',
}
</script>
<style>
.line{
	border-bottom: 2px solid white;
}
</style>
<style scoped>
</style>
