import { render, staticRenderFns } from "./HumanResource.vue?vue&type=template&id=11dc8e76&scoped=true&"
import script from "./HumanResource.vue?vue&type=script&lang=js&"
export * from "./HumanResource.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11dc8e76",
  null
  
)

export default component.exports